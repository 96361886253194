import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
		<p>
		  Thanks for visiting Colligenda Systems.
		</p>
        <p>
          Please come back again once we've got things up and running!
        </p>
      </header>
    </div>
  );
}

export default App;
